
import { defineComponent, onBeforeUnmount, onMounted, reactive, watch } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import LogoSelect from '@/components/pages/shared/LogoSelect.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useModals } from '@/compositions/modals'
import { editorBtns } from '@/definitions/shared/editor/data'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { useBottomBar } from '@/compositions/bottomBar'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmButton,
    DetailsHero,
    TmDropdown,
    TmFormLine,
    TmDropdownItem,
    InfoCard,
    LogoSelect,
    PageContent,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const { provideData, getDiscardFunc } = useBottomBar()
    const { openModal } = useModals()
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'Public reply', url: '' },
    ]

    const btnsList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.tags,
      editorBtns.image,
    ]
    const tags = [
      'First name',
      'Last name',
      'Phone',
      'Email',
      'Company name',
      'Test field',
    ]

    const state = reactive({
      footer: isEmptyMode.value ? '' : 'This email was sent to you by Textmagic Ltd.\n34 Thame Road, Great Haseley, OX44 7JF, United Kingdom',
      subject: isEmptyMode.value ? '' : 'Feedback: {{ticket_id}} - {{ticket_subject}}',
    })

    const defaultState = Object.assign({}, state)

    watch(() => state, (newValue) => {
      JSON.stringify(newValue) !== JSON.stringify(defaultState) ? provideData(1) : getDiscardFunc.value()
    }, { deep: true })

    const restoreEnabled = () => {
      Object.assign(state, defaultState)
    }

    onMounted(() => {
      provideData(0, restoreEnabled)
    })

    onBeforeUnmount(() => {
      getDiscardFunc.value()
    })

    return {
      tags,
      btnsList,
      openModal,
      state,
      breadcrumbs,
    }
  },
})
